.transaction_sect {
    padding: 40px 80px;
}

.transaction_sect h2,
.withdraw_sect_hero h2 {
    color: #071278;
    font-weight: 600;
    font-size: 30px;
}

.transaction_table {
    margin-top: 50px;
}

.transaction_table_head {
    margin-bottom: 50px;
}

.transaction_table_head,
.transaction_table_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transaction_table_head h3 {
    font-weight: 600;
    color: #071278;
    font-size: 18px;
}

.transaction_table_body {
    font-size: 18px;
    font-weight: 500;
    color: #071278;
    margin-bottom: 30px;
}

.date {
    width: 30%;
}

.type {
    width: 10%;
}

.amount,
.status,
.reference {
    width: 20%;
}

.transaction_table_body .btn {
    font-size: 11px;
    font-weight: 400;
}

@media(max-width:600px) {
    .transaction_table {
        overflow-x: scroll;
    }
}