.transaction_sect {
    padding: 40px;
}

.transaction_sect h2,
.withdraw_sect_hero h2 {
    color: var(--color1);
    font-weight: 600;
    font-size: 30px;
}

.transaction_table {
    margin-top: 50px;
}

.transaction_table_head {
    margin-bottom: 50px;
}

.transaction_table_head,
.transaction_table_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transaction_table_head h3 {
    font-weight: 600;
    color: var(--color3);
    font-size: 18px;
}

.transaction_table_body {
    font-size: 18px;
    font-weight: 500;
    color: var(--color1);
    margin-bottom: 30px;
}

.date {
    width: 30%;
}

.type {
    width: 10%;
}

.amount,
.status,
.reference {
    width: 20%;
}

.transaction_table_body .btn {
    font-size: 11px;
    font-weight: 400;
}

@media(max-width:600px) {
    .transaction_sect {
        padding: 0;
    }

    .transaction_table,
    .table_body {
        overflow-x: scroll;
    }

    .transaction_table_body,
    .transaction_table_head,
    .table_body_header,
    .table_body_body {
        width: 1000px;
    }
}