.add_food_item_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 100px;
    justify-content: space-between;
    row-gap: 50px;
    column-gap: 30px;
    padding-bottom: 20px;
}

label {
    border-radius: 8px;
}

.add_food_item_form .food_item_inputs {
    width: 100%;
}



.add_food_item_form_item_one h4 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: var(--color3);
    margin-bottom: 10px;
}

.add_food_item_form_item_one {
    display: flex;
    gap: 15px;
    align-items: center;
}

.add_food_item_form_item_two {
    justify-self: flex-end;
}

.add_food_item_form_labels {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: var(--color1);
    margin-bottom: 10px;
}

.add_food_item_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.go_back {
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
}

.add_food_item_header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.customer_details .application_statistics_container>div {
    width: 16.6%;
    padding: 10px;
}

.customer_details .application_statistics_container {
    margin-top: 30px;
}

.customer_details .application_statistics_container h3 {
    font-size: 16px;
}

.customer_details .application_statistics_container p {
    margin: 0;
}

.customer_btn_flex {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 30px 0;
}

@media(max-width:600px) {

    .customer_details .application_statistics_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .customer_details .application_statistics_container>div {
        width: 100%;
    }

    .add_food_item_form {
        padding: 0px !important;
        grid-template-columns: 1fr !important;
        margin-top: 20px;
    }
}